import Typesense from 'typesense'

export interface SearchParameters {
    'q': string,
    'query_by': string,
    'sort_by': string,
}

export const useTypesenseClient = (protocol = 'https', port = 443): any => {
    const nearestNode = import.meta.env.VITE_TYPESENSE_NEAREST ?? 'ai80e5x2617k9rpzp-1.a1.typesense.net'
    const clusters = import.meta.env.VITE_TYPESENSE_CLUSTER ?? 'ai80e5x2617k9rpzp-1.a1.typesense.net'
    const searchOnlyApiKey = import.meta.env.VITE_TYPESENSE_API ?? 'qzZJS983iI49ZQuzFDFI6mrk3b36Ov3S'

    return new Typesense.Client({
        nearestNode: {
            host: nearestNode,
            port: port,
            protocol: protocol,
        },
        nodes: [
            {
                host: clusters.split(';')[0],
                port: port,
                protocol: protocol,
            },
            {
                host: clusters.split(';')[1],
                port: port,
                protocol: protocol,
            },
            {
                host: clusters.split(';')[2],
                port: port,
                protocol: protocol,
            },
        ],
        apiKey: searchOnlyApiKey,
        connectionTimeoutSeconds: 2,
        logger: false,
    })
}

export const useSearchParameters = (searchParams: any, sortParams: any, queryBy = 'title'): SearchParameters => {

    const sort = Array.isArray(sortParams) ? 
        sortParams.map(param => param.type + ':' + param.order).join(', ') : 
        sortParams.type + ':' + sortParams.order

    const searchParameters = {
        'q': searchParams?.searchQuery,
        'query_by': queryBy,
        'filter_by': searchParams?.filterQuery,
        'sort_by': sort,
    }

    return searchParameters
}